import { z } from "zod";
import { PurchaseOrderExports } from "../../interface";
import { Detail, Person } from "./order";

export interface KeyValue {
	key: string;
	value: string;
}

export interface Shipping {
	id: number;
	dest: ShippingDest;
	method: string;
	tracking?: string;
	shipping_date?: string;
	delivery_date?: string;
	cod?: number;
	incorrect: boolean;
	orders: ShippingOrder[];
	operations: {
		operation_type: string;
		attributes: object;
		create_at: string;
	}[];
	shippingState?: string;
	locationGroupId?: number;
}
type ShippingDest = Person & { codtax?: number };
export type ShippingOrder = {
	id: number;
	method: string;
	idtext: string;
	state?: unknown[];
	create_at: string;
	orderShippingIdx?: number;
	items: ShippingOrderItem[];
	delivery_date: string | {
		date: string;
		timezone: string;
		shipping: string;
	};
	orderEpoch: number;
	orderer: Person;
	detail: Detail;
};
export type ShippingOrderItem = {
	sku: string | null;
	name: string;
	attributes: object;
	quantity: number;
	source?: KeyValue[];
	itemName?: string;
	price?: number;
	purchaseOrderItemIds: string[];
	is_fictitious: boolean;
	purchaseOrderExports: PurchaseOrderExports[];
	/** 分割用識別子 */
	splitId?: string;
};

export interface ShippingBase {
	id: number;
	dest: Person;
	method: string;
	tracking?: string;
	delivery_date?: string;
	cod?: number;
	orders: ShippingBaseOrder[];
}
export interface ShippingBaseOrder {
	method: string;
	idtext: string;
	orderShippingIdx?: number;
	items: ShippingBaseOrderItem[];
}
export interface ShippingBaseOrderItem {
	sku: string | null;
	quantity: number;
	source: KeyValue[];
	splitId?: string;
}

export type ShippingPending = {
	type: string;
	detail?: string;
};

export const schemaGetShippingPendingsResponse = z.object({
	id: z.string().uuid(),
	type: z.string(),
	detail: z.string().optional(),
}).array();
export type GetShippingPendingsResponse = z.infer<typeof schemaGetShippingPendingsResponse>;

export const schemaPostShippingPendingRequest = z.object({
	type: z.string(),
	detail: z.string(),
});
export const schemaPostShippingPendingResponse = z.object({
	pendingId: z.string().uuid(),
});
export const schemaPutShippingPendingRequest = schemaPostShippingPendingRequest.partial();
export const schemaPutShippingPendingResolveRequest = schemaPostShippingPendingRequest.partial();
