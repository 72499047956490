import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { Shipping } from '../../../types';
import { Barcode } from '../Barcode';
import { ItemListRow } from './ItemListRow';
import { PickingListHeader } from './PickingListHeader';
import { PickingListShipping } from './PickingListShipping';

export interface PickingListProps {
	shipping: Shipping;
	skuPrefix: string;
	shippingPrefix: string;
	css?: string;
	settings: sasagase.GetOrderInstructionSetting[];
}

export const PickingList: React.FC<PickingListProps> = (props) => {
	const { shipping: { method, orders } } = props;
	if (orders.length > 1) {
		throw new Error();
	}
	const order = orders[0];

	const items = orders.flatMap(order => order.items).map((item, idx) => ({ ...item, idx: idx + 1 }));

	return (
		<div className="page">
			<PickingListHeader method={method} order={order} />
			<PickingListShipping shipping={props.shipping} />
			<section className="detail">
				<h1>明細</h1>
				<table>
					<thead>
						<tr>
							<th className="w10 textC">#</th>
							<th className="w100">SKU</th>
							<th>商品名／商品オプション</th>
							<th className="w40">個数</th>
							<th className="w80">備考</th>
						</tr>
					</thead>
					<tbody>
						{
							items.map((item, idx) => <ItemListRow item={item} key={`${item.sku}-${idx}`} prefix={props.skuPrefix} settings={props.settings} />)
						}
					</tbody>
				</table>
				<div className="detailOther">
					<div className="barcode"><Barcode id="barcode" jsbarcode-value={ props.shippingPrefix + props.shipping.id } jsbarcode-height={ 50 }/></div>
					<div className="postage">
						<table>
							<thead>
								<tr>
									<th className="w80">送料</th>
									<th className="w80">代引き手数料</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="textR">{ order.detail.postagePrice ? order.detail.postagePrice.toLocaleString() : '-' } 円</td>
									<td className="textR">{ order.detail.deliveryPrice ? order.detail.deliveryPrice.toLocaleString() : '-' } 円</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</div>
	);
};