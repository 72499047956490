import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { toBR } from '../../../lib';
import { ShippingOrderItem } from '../../../types';
import { QRCode } from '../../molecules/QRCode';

interface ItemListRowProps {
	item: ShippingOrderItem & { idx: number };
	prefix: string;
	settings: sasagase.GetOrderInstructionSetting[];
}

export const ItemListRow: React.FC<ItemListRowProps> = (props) => {
	const { item, prefix, settings } = props;
	const name = item.name_s ?? item.name;
	const source = item.source || [];
	const quantity = item.quantity > 1 ? <span className="strong xl">{item.quantity}</span> : <>{item.quantity}</>;
	const valueClass = (key: string) => {
		return settings?.some(setting => 
			(setting.matchType === "exact" && setting.keyword === key) ||
			(setting.matchType === "partial" && key.includes(setting.keyword))
		) ? 'strong x2' : '';
	};
	const poNumber = item.purchaseOrderExports?.map((purchaseObj) => {
		return purchaseObj.purchaseOrder?.poNumber ?? '';
	}).join("\n") || "";

	return (
		<tr>
			<td className="textC">{item.idx}</td>
			<td className="textC">{prefix}{item.sku}</td>
			<td>
				<p className="itemName">{name}</p>
				{source.length > 0 &&
					<p className="itemOptions">
						{source.map(s => <React.Fragment key={s.key}>{s.key}:<span className={valueClass(s.key)}>{s.value}</span><br /></React.Fragment>)}
					</p>
				}
			</td>
			<td className="textR">{quantity}</td>
			<td>
				{toBR(poNumber)}
				{item.itemUrl && <QRCode url={item.itemUrl} isIncludeMargin={true} />}
			</td>
		</tr>
	);
};